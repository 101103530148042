<template>
  <div class="dispose-home">
    <div class="header">
      <el-button
        type="primary"
        class="common-button commonBtn"
        icon="iconfont icon-rusuodengji"
        @click="addDispose"
      >
        新建治疗措施
      </el-button>
      <el-button
        type="primary"
        class="common-button commonBtn"
        icon="iconfont icon-rusuodengji"
        @click="addEvaluate"
      >
        新建评价
      </el-button>
    </div>
    <div class="block" v-loading="loading">
      <div class="plan" v-for="item in disposeList" :key="item.id">
        <div class="plan-left">
          <div class="plan-time">
            <div class="plan-time-above">
              {{ item.createAt.substr(5, 2) }}月{{ item.createAt.substr(8, 2) }}日
            </div>
            <div class="plan-time-below">
              {{ item.createAt.substr(0, 4) }}
            </div>
          </div>
          <div class="plan-column">
            <div class="column" v-if="item.id !== disposeList[disposeList.length - 1].id"></div>
          </div>
        </div>
        <div class="plan-right">
          <div class="block-header"></div>
          <div class="block-treat" v-if="item.detail">
            <span>{{ item.detail }}</span>
          </div>
          <div class="block-treat" v-if="item.chiefComplaint">
            <span>主诉：{{ item.chiefComplaint }}</span>
          </div>

          <template v-if="item.operation">
            <div class="block-treat">
              <span>手术：</span>
              <span>{{ item.operation.examineNames }}</span>
            </div>
            <div class="block-treat">
              <span>主刀医生：</span>
              <span>{{ item.operation.doctorName }}</span>
            </div>
            <div class="block-treat">
              <span>手术时间：</span>
              <span>
                {{
                  item.operation.studyStartDate ||
                  item.operation.studyDate ||
                  item.operation.planStudyDate
                }}
              </span>
            </div>
          </template>

          <div class="block-treat" v-else>
            <span v-if="item.operations.length !== 0">手术：</span>
            <span v-for="(ele, index) in item.operations" :key="ele.id">
              {{ ele.operationName }}
              {{ index !== item.operations.length - 1 ? '，' : '' }}
            </span>
          </div>
          <div class="block-treat" v-if="item.doctorName && !item.operation">
            <span>主刀医生：{{ item.doctorName }}</span>
          </div>
          <div class="block-treat" v-if="item.drugs">
            <span>用药：</span>
            <span v-for="(ele, index) in item.drugs" :key="ele.id">
              {{ ele.drugName + ele.dose + ele.doseUnit }}
              {{ index !== item.drugs.length - 1 ? '，' : '' }}
            </span>
          </div>
          <div class="block-treat" v-if="item.stopMedicationDate">
            <span>{{ item.stopMedicationDate.substring(0, 16) }} 停用药物</span>
            <span v-show="item.stopMedicationReason">{{ item.stopMedicationReason }}</span>
            <span v-show="item.stopMedicationReasonOther">
              {{ item.stopMedicationReasonOther }}
            </span>
          </div>
          <div class="card-footer">
            <div class="card-footer-label">
              <span v-if="item.doctorName">{{ item.doctorName }}</span>
              <span v-else-if="item.userName">{{ item.userName }}</span>
              <span>{{ item.createName }}</span>
              创建于
              <span>{{ item.createAt }}</span>
            </div>
            <div class="card-footer-btn">
              <el-button
                v-if="item.drugs"
                :disabled="item.isStop === 1"
                @click="stopItem(item)"
                type="default"
                size="small"
                class="button"
              >
                停用药物
              </el-button>

              <el-button
                v-if="item.operation"
                @click="gotoOperation(item.operation)"
                type="default"
                size="small"
                class="button"
              >
                查看手术详情
              </el-button>

              <el-button
                v-if="!item.operation && item.operations && item.operations.length > 0"
                @click="addOperation(item.id)"
                type="default"
                size="small"
                class="button"
              >
                手术登记
              </el-button>

              <el-button
                type="primary"
                size="small"
                @click="checkPlan(item)"
                class="button commonBtn"
              >
                处置方案
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <opinion-dialog ref="opinionDialog" :info="info" @save="dialogSave"></opinion-dialog>

    <patient-info-input-dialog
      ref="patientInfoInputDialog"
      :doctorList="doctorList"
      @save="dialogSave"
    ></patient-info-input-dialog>
    <evaluate-dialog ref="evaluateDialog" :info="info"></evaluate-dialog>
    <stop-medical-dialog
      ref="stopMedicalDialog"
      @save="medication_dialogSave"
    ></stop-medical-dialog>
  </div>
</template>

<script>
import OpinionDialog from './components/OpinionDialog.vue'
import EvaluateDialog from './components/EvaluateDialog'
import StopMedicalDialog from './components/StopMedicalDialog'
import PatientInfoInputDialog from './components/PatientInfoInputDialog'

export default {
  name: 'Dispose',
  components: {
    OpinionDialog,
    EvaluateDialog,
    StopMedicalDialog,
    PatientInfoInputDialog
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      disposeList: [],
      doctorList: [],
      stopMedicationInfo: null
    }
  },
  created() {
    this.getDisposeList()
    this.getDoctorList()
  },
  methods: {
    medication_dialogSave(val) {
      if (!val) return
      const params = new FormData()
      for (let item in val) {
        params.append(item, val[item])
      }
      this.$api
        .post(`/v1/webconsole/solution/dispose/drug/stop/${this.stopMedicationInfo.id}`, params)
        .then((res) => {
          if (res.data.status === 200) {
            this.stopMedicationInfo = null
            this.getDisposeList()
            this.$message.success('停用成功！')
          }
        })
    },

    gotoOperation(val) {
      const { href } = this.$router.resolve({
        path: `/Detail/BaseInfo?operationId=${val.uuid}`
      })
      window.open(href, '_blank')
    },
    stopItem(val) {
      this.stopMedicationInfo = val
      this.$refs.stopMedicalDialog.Show()
    },

    getDoctorList() {
      this.$api.get(`/v1/webconsole/study/doctors`).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({
              userName: item.userName,
              userId: item.userID
            })
          })
          this.doctorList = arr
        }
      })
    },
    getDisposeList() {
      this.$api.get(`/v1/webconsole/solution/dispose/list/${this.info.id}`).then(
        (res) => {
          if (res.data && res.data.data) {
            this.disposeList = res.data.data
          }
          this.loading = false
        },
        () => {
          this.loading = false
        }
      )
    },

    addOperation(id) {
      this.$refs.patientInfoInputDialog.Show(id)
    },
    addDispose() {
      sessionStorage.setItem('solutionId', this.info.id)
      this.$refs.opinionDialog.Show('add', this.disposeList)
    },
    addEvaluate() {
      sessionStorage.setItem('solutionId', this.info.id)
      this.$refs.evaluateDialog.Show('add')
    },
    checkPlan(val) {
      this.setItem(val)
    },

    dialogSave() {
      this.loading = true
      this.disposeList = []
      setTimeout(() => {
        this.getDisposeList()
      }, 500)
    },
    setItem(val) {
      sessionStorage.setItem('solutionId', val.solutionId)
      sessionStorage.setItem('disposeId', val.id)
      this.$refs.opinionDialog.Show('edit', this.disposeList)
    }
  }
}
</script>

<style lang="less" scoped>
.dispose-home {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    height: 3rem;
    background: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

.block {
  flex: 1;
  overflow: auto;
  padding-top: 20px;
  background-color: #f6f6f6;

  .plan {
    min-height: 240px;
    display: flex;
    justify-content: center;

    .plan-left {
      min-height: 240px;
      width: 100px;

      .plan-time {
        width: 98px;
        height: 50px;
        background: #2d507e;
        border-radius: 10px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .plan-time-above {
          height: 50%;
          font-weight: 700;
          font-size: 18px;
        }
        .plan-time-below {
          height: 50%;
        }
      }
      .plan-column {
        height: calc(100% - 50px);
        .column {
          width: 4px;
          height: 100%;
          background: #75b1bc;
          display: inline-block;
          vertical-align: top;
          margin-left: 48px;
        }
      }
    }
    .plan-right {
      margin-left: 30px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      width: 40%;
      background: #ffffff;
      display: inline-block;
      border-radius: 8px;
      .block-header {
        font-size: 20px;
        font-weight: 700;
        margin: 10px 0px 15px 10px;
      }
      .block-treat {
        margin-left: 10px;
        margin-bottom: 15px;
        .name {
          font-size: 16px;
          font-weight: 800;
        }
      }
      .card-footer {
        height: 40px;
        line-height: 40px;
        .card-footer-label {
          float: left;
          margin-left: 10px;
          color: #a1a1a1;
        }
        .card-footer-btn {
          float: right;
          margin-right: 10px;
        }
      }
      .button {
        margin-left: 10px;
      }
    }
  }
}
</style>
