<style lang="less" scoped>
.input-form-item-content {
  width: 180px;
}
.el-divider__text.is-left {
  font-size: 18px;
}
/deep/.dialog-content__big {
  width: 95rem;
  height: 46rem;
}
/deep/.dialog-content__mini {
  width: 37.5rem;
}
</style>

<template>
  <cm-dialog-border
    :size="dialogType"
    :zIndex="zIndex"
    title="处置方案"
    v-show="isShow"
    @close="Close"
  >
    <DisposeScheme
      :solutionId="solutionId"
      :disposeId="disposeId"
      :infoType="infoType"
      :info="info"
      :list="list"
      @close="Close"
      @save="open_save"
    ></DisposeScheme>
  </cm-dialog-border>
</template>

<script>
import CmDialogBorder from '@/components/CmDialogBorder.vue'
import DisposeScheme from './DisposeScheme/index.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  name: 'OpinionDialog',
  components: {
    CmDialogBorder,
    DisposeScheme
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      solutionId: '',
      disposeId: '',
      infoType: '',
      dialogType: 'big',
      list: []
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    Show(type, val) {
      if (this.info.typeCategory !== '系统治疗') {
        this.dialogType = 'mini'
      } else {
        this.dialogType = 'big'
      }
      this.infoType = type
      this.solutionId = sessionStorage.getItem('solutionId')
      if (type === 'edit') {
        this.disposeId = sessionStorage.getItem('disposeId')
      } else {
        this.disposeId = ''
      }
      if (val.length > 0) {
        this.list = val
      }
      this.isShow = true
    },
    open_save() {
      this.$emit('save')
      this.Close()
    },

    Close() {
      this.infoType = ''
      this.isShow = false
    }
  }
}
</script>
