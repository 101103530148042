<style lang="less" scoped>
.info-content {
  height: 20rem;
  .main-item {
    margin: 20px 20px;
    display: flex;
    span {
      width: 120px;
    }
    .el-input {
      width: 300px;
    }
    /deep/.el-select {
      width: 300px;
    }

    .input_item_input {
      width: 100%;
    }

    .input_item_textarea {
      width: 300px;
    }
  }
}
</style>

<template>
  <cm-dialog-border size="mini" :zIndex="zIndex" title="评价" v-show="isShow" @close="Close">
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-edit"
        @click="saveClick"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="info-content">
      <div class="main-item">
        <span>总体评价：</span>
        <cqt-select
          class="input_item"
          activeUrl="/v1/webconsole/comboData/get/SOLUTION_effect"
          :value.sync="evaluateInfo.ote"
        />
      </div>
      <div class="main-item">
        <span>评价日期：</span>
        <date-panel
          class="input_item"
          ref="datePanel"
          v-model="evaluateInfo.opinionTime"
          type="date"
          align="center"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        ></date-panel>
      </div>
      <div class="main-item">
        <span>说明：</span>
        <cqt-input
          class="input_item_textarea"
          placeholder="请输入"
          type="textarea"
          :rows="4"
          v-model="evaluateInfo.memo"
          size="large"
        />
      </div>
    </div>
  </cm-dialog-border>
</template>

<script>
import deepClone from '@/plugins/clone'
import CqtSelect from '@/components/cqt/CqtSelect.vue'
import CqtInput from '@/components/cqt/CqtInput.vue'
import CmDialogBorder from '@/components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import DatePanel from '../../../../../Menu/components/date-picker/index'

export default {
  name: 'OpinionDialog',
  components: {
    CmDialogBorder,
    CqtSelect,
    DatePanel,
    CqtInput
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isShow: false,
      zIndex: 9,
      solutionId: '',
      infoType: '',
      evaluateInfo: {
        ote: '',
        opinionTime: '',
        memo: ''
      }
    }
  },
  created() {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month =
      currentDate.getMonth() + 1 > 9
        ? currentDate.getMonth() + 1
        : '0' + (currentDate.getMonth() + 1)
    const day = currentDate.getDate() > 9 ? currentDate.getDate() : '0' + currentDate.getDate()
    const today = year + '-' + month + '-' + day

    this.evaluateInfo.opinionTime = today
  },
  mounted() {},
  computed: {},
  methods: {
    Show(type, val) {
      this.infoType = type
      this.solutionId = sessionStorage.getItem('solutionId')
      if (this.infoType === 'edit') {
        this.evaluateInfo = deepClone(val)
      } else {
        this.evaluateInfo = {
          ote: '',
          opinionTime: this.evaluateInfo.opinionTime,
          memo: ''
        }
      }
      this.isShow = true
    },

    saveClick() {
      if (!this.evaluateInfo.ote) {
        return this.$message.warning('请填写总体评价')
      }
      if (!this.evaluateInfo.opinionTime) {
        return this.$message.warning('请填写评价日期')
      }
      let data = {
        id: this.evaluateInfo.id || null,
        solutionId: this.solutionId,
        ote: this.evaluateInfo.ote, // 目标病灶
        opinionTime: this.evaluateInfo.opinionTime,
        memo: this.evaluateInfo.memo
      }
      const params = new FormData()
      for (let item in data) {
        params.append(item, data[item])
      }
      this.$api.post(`/v1/webconsole/solution/opinion/save/${this.info.id}`, params).then((res) => {
        if (res.data.status === 200) {
          this.$message.success('保存成功')
          this.$emit('save')
          this.Close()
        }
      })
    },

    Close() {
      this.infoType = ''
      this.isShow = false
    }
  }
}
</script>
