<style scoped lang="less">
.selected-text {
  background-color: #f0f0f0;
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="内容编辑"
    v-if="isShow"
    style="width: 500px"
    @close="handleCancel"
  >
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="handleConfirm"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="handleCancel"
      >
        关闭
      </el-button>
      <div class="infoTitle">
        预览：
        <span v-for="(item, index) in input_list" :key="'list1' + index">
          <span>{{ item.medicineName }}</span>
          <span style="color: red">{{ item.consumption }}</span>
          <span v-if="item.medicineDoseUnit">{{ item.medicineDoseUnit }}</span>
          &ensp;
        </span>
        <span v-for="(item, index) in inputList" :key="'list2' + index">
          <span>{{ item.pre }}</span>
          <span v-if="item.val !== null" style="color: red">{{ item.val }}</span>
        </span>
      </div>
    </div>
    <div class="selected-text">
      <div class="infoTable">
        <el-table
          :data="input_list"
          :header-cell-style="{ padding: 0 }"
          border
          style="width: 100%; height: 382px"
          highlight-current-row
        >
          <el-table-column label="药品" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.medicineName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="规格" align="center" width="80">
            <template slot-scope="scope">
              <div>{{ scope.row.medicineDose }}{{ scope.row.medicineDoseUnit }}</div>
            </template>
          </el-table-column>
          <el-table-column label="用量" align="center" width="150">
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                type="text"
                @keyup.native="handleKeyDown($event)"
                v-model="scope.row.consumption"
                size="mini"
              >
                <template v-if="scope.row.medicineDoseUnit" slot="append">
                  {{ scope.row.medicineDoseUnit }}
                </template>
              </el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </CmDialogBorder>
</template>
<script>
import CmDialogBorder from '@/components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '@/plugins/clone'

export default {
  name: 'ListContentDialog',
  components: {
    CmDialogBorder
  },

  data() {
    return {
      input_list: [],
      inputList: [],
      textList: [],
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      f_flag: true,
      isSelect: true,
      input: '',
      options: [],
      tempFilterOptions: [],
      executeNameId: [],
      executors: [],
      useTypeId: '',
      useType: '',
      useTypeValue: '',
      infoId: '',
      executeDate: null,
      info: {
        executors: [],
        medicationCombos: []
      },
      arr: [],
      solutionId: '',
      disposeId: '',
      type: ''
    }
  },
  created() {},
  methods: {
    handleKeyDown(e) {
      var val = e.target.value
      // 限制只能输入一个小数点
      if (val.indexOf('.') != -1) {
        var str = val.substr(val.indexOf('.') + 1)
        if (str.indexOf('.') != -1) {
          val = val.substr(0, val.indexOf('.') + str.indexOf('.') + 1)
        }
      }
      e.target.value = val.replace(/[^0-9.<>]/g, '')
    },
    Show(val, type) {
      this.type = type
      this.solutionId = sessionStorage.getItem('solutionId')
      this.disposeId = sessionStorage.getItem('disposeId')
      if (type === 'add') {
        this.input_list = deepClone(val)
      } else {
        this.$api.get(`/v1/webconsole/medication/packages/medicines/${val.uuid}`).then((res) => {
          this.input_list = res.data.data
        })
      }

      this.isShow = true
    },
    handleConfirm() {
      for (let i = 0; i < this.input_list.length; i++) {
        const ele = this.input_list[i]
        if (ele.consumption === '' || ele.consumption === undefined) {
          return this.$message.error('有未填项,无法保存')
        }
      }
      if (this.type === 'add') {
        this.input_list.forEach((res) => {
          let data = {}
          data.drugName = res.medicineName
          data.pubdrugIndex = res.uuid
          data.dose = res.consumption
          data.doseUnit = res.medicineDoseUnit
          this.arr.push(data)
        })
      } else if (this.type === 'add1') {
        this.input_list.forEach((res) => {
          let data = {}
          data.drugName = res.medicineName
          data.pubdrugIndex = res.medicineId
          data.dose = res.consumption
          data.doseUnit = res.medicineDoseUnit
          this.arr.push(data)
        })
      }

      this.$emit('pickedObjectEmit', this.arr)
      this.arr = []
      this.input_list = []
      this.type = ''
      this.isShow = false
    },
    handleCancel() {
      this.solutionId = ''
      this.disposeId = ''
      this.input_list = []
      this.isShow = false
    }
  },

  computed: {},
  watch: {}
}
</script>
