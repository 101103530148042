<style scoped lang="less">
/deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #b8d4f0 !important;
}
/deep/ .tableEditRow {
  background: yellow;
}
.main-content {
  background: #fff;
  .record-button-left {
    width: 48%;
  }
  .record-button-right {
    width: 50%;
    display: flex;
    align-items: center;
    .search-input {
      width: 200px;
    }
  }
  .table-box {
    width: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    .left-box {
      width: 48%;
      .left-box-table {
        height: 300px;
        .el-divider__text.is-left {
          font-size: 18px;
        }
        .drug-table {
          border-left: none;
          border-right: none;
          border-bottom: none;
        }
      }
      .left-box-tabs {
        .el-divider__text.is-left {
          font-size: 18px;
        }
        .input-form-item-content {
          width: 400px;
        }
        .item-row {
          padding-left: 20px;
          padding-top: 10px;
        }
        .left-box-Btn {
          height: 2rem;
          line-height: 2rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }

    .medicine-box {
      width: 52%;
      height: 100%;
      padding-left: 5px;
      border-left: 1px solid #eee;
      .inner-content-right {
        width: 100%;
        height: 100%;
        /deep/.el-tabs__item.is-active {
          border: none;
        }
        .inner-tabs-right {
          height: calc(100% - 4px);
          /deep/ .el-tabs__content {
            padding: 0;
            height: calc(100% - 2.25rem);
          }
        }
      }
      .medicine-table {
        width: 100%;
        height: 100%;
      }
    }
  }

  .el-table .cell {
    padding: 0;
  }
}
</style>
<style>
.my-label {
  min-width: 130px;
  height: 30px;
  line-height: 30px !important;
}
</style>
<template>
  <div class="main-content" v-loading="loading">
    <div class="crt-content">
      <div class="record-button-left">
        <el-button
          v-if="isEdit"
          type="primary"
          class="common-button commonBtn"
          icon="el-icon-edit"
          @click="editClick"
        >
          编辑
        </el-button>

        <el-button
          v-else
          type="success"
          class="common-button commonBtn"
          icon="el-icon-edit"
          @click="saveClick"
        >
          保存
        </el-button>
        <el-button
          type="danger"
          class="common-button commonBtn"
          icon="el-icon-close"
          v-debounce="Close"
        >
          关闭
        </el-button>
      </div>

      <div class="record-button-right" v-show="info.typeCategory === '系统治疗'">
        <el-button
          v-show="medicationIsShow"
          type="primary"
          class="common-button commonBtn"
          icon="el-icon-sort"
          @click="isInfo = !isInfo"
          v-if="!isEdit"
        >
          {{ isInfo ? '切换到用药套餐' : '切换到药品' }}
        </el-button>
        <div v-show="isInfo">
          <div style="display: flex">
            <el-button
              type="success"
              class="common-button commonBtn"
              icon="el-icon-edit"
              @click="handleMod2"
              v-if="!isEdit"
            >
              添加药品
            </el-button>
            <div class="search-input" v-if="!isEdit">
              <el-input v-model="searchValue" placeholder="输入关键字进行搜索" clearable></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-box">
      <div class="left-box">
        <div class="left-box-tabs">
          <div class="top-item-row">
            <div class="item-row">
              <el-descriptions :column="1" :colon="false">
                <el-descriptions-item label="主诉：" label-class-name="my-label">
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="dispose.chiefComplaint">
                      {{ dispose.chiefComplaint }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    v-model="disposeInfo.chiefComplaint"
                    placeholder=""
                    clearable
                  ></el-input>
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions :column="1" :colon="false">
                <el-descriptions-item label="处置概述：" label-class-name="my-label">
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="dispose.detail">
                      {{ dispose.detail }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    v-model="disposeInfo.detail"
                    placeholder=""
                    clearable
                  ></el-input>
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions :column="1" :colon="false">
                <el-descriptions-item label="治疗方式：" label-class-name="my-label">
                  <el-checkbox-group v-model="checkedList" @change="handleChangeType">
                    <el-checkbox v-for="item in disposeTypeList" :label="item" :key="item">
                      {{ item }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <el-radio-group
                    style="margin-left: 5px"
                    v-model="lastMedication"
                    v-show="medicationIsShow && infoType === 'add'"
                  >
                    <el-radio
                      @click.native.prevent="selectRadio('继续使用上次药物')"
                      label="继续使用上次药物"
                    ></el-radio>
                    <!-- <el-radio
                      @click.native.prevent="selectRadio('更改用药')"
                      label="更改用药"
                    ></el-radio> -->
                  </el-radio-group>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions :column="1" :colon="false" v-show="operationIsShow">
                <el-descriptions-item label="拟施手术：" label-class-name="my-label">
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="operationNameShow">
                      {{ operationNameShow }}
                    </el-tag>
                  </div>
                  <el-select
                    v-else
                    v-model="value"
                    multiple
                    filterable
                    remote
                    placeholder="请输入关键词"
                    :remote-method="remoteMethod"
                    @clear="clear"
                    class="input-form-item-content"
                  >
                    <el-option
                      v-for="item in operationOptions"
                      :key="item.dictIndex"
                      :label="item.dictName"
                      :value="item.dictIndex"
                    ></el-option>
                  </el-select>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions :column="1" :colon="false" v-show="operationIsShow">
                <el-descriptions-item label="主治医生：" label-class-name="my-label">
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="dispose.doctorName">
                      {{ dispose.doctorName }}
                    </el-tag>
                  </div>
                  <el-select v-else class="input-form-item-content" v-model="disposeInfo.doctorId">
                    <el-option
                      v-for="(item, index) in doctorOptions"
                      :key="index"
                      :label="item.userName"
                      :value="item.userID"
                    ></el-option>
                  </el-select>
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions :column="1" :colon="false">
                <el-descriptions-item label="处置制定时间：" label-class-name="my-label">
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="dispose.disposeTime">
                      {{ dispose.disposeTime }}
                    </el-tag>
                  </div>
                  <el-date-picker
                    class="input-form-item-content"
                    v-model="disposeInfo.disposeTime"
                    type="date"
                    align="center"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-else
                  ></el-date-picker>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
        <div class="left-box-table" v-show="medicationIsShow">
          <el-divider content-position="left">用药明细</el-divider>
          <el-table
            class="drug-table"
            :data="drugTable"
            ref="reportDataInfo"
            :header-cell-style="{ padding: 0 }"
            border
            height="calc(100% - 15px)"
            style="width: 100%"
            highlight-current-row
          >
            <el-table-column
              prop="drugName"
              label="药品名称"
              show-overflow-tooltip
              align="center"
              min-width="160"
            ></el-table-column>
            <el-table-column prop="dose" label="剂量" width="120" align="center"></el-table-column>
            <el-table-column
              prop="doseUnit"
              label="规格"
              width="120"
              align="center"
            ></el-table-column>
            <el-table-column align="center" label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  v-if="!isEdit"
                  size="mini"
                  @click="handleClick(scope.row)"
                  icon="el-icon-delete"
                  type="danger"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="medicine-box" v-show="medicationIsShow">
        <div class="inner-content-right" v-show="!isInfo">
          <el-tabs class="inner-tabs-right" @tab-click="medicationTabClick">
            <el-tab-pane v-for="item in medicationData" :key="item.uuid" :label="item.groupTitle">
              <el-tree
                :props="defaultProps"
                :data="medicationTreeData"
                @current-change="handleCheckChangeMedication"
                :default-expand-all="true"
                highlight-current
              >
                <span class="custom-tree-node" slot-scope="{ data }">
                  <span>{{ data.title }}</span>
                </span>
              </el-tree>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="medicine-table" v-show="isInfo">
          <el-table
            :header-cell-style="{ padding: 0 }"
            :data="allMedicineData"
            style="width: 100%"
            :row-key="getRowKeys"
            @sort-change="sortChange3"
            ref="multipleTable2"
            height="calc(100% - 36px)"
            @row-click="changeCurrentRow2"
            @selection-change="handleSelectionChange2"
            border
          >
            <el-table-column align="center" type="index" width="30"></el-table-column>
            <el-table-column
              type="selection"
              :reserve-selection="true"
              width="45"
            ></el-table-column>
            <el-table-column
              header-align="center"
              label="药品名称"
              align="center"
              width="180"
              show-overflow-tooltip
              sortable="custom"
              prop="medicineName"
            ></el-table-column>
            <el-table-column
              header-align="center"
              label="规格"
              align="center"
              sortable="custom"
              prop="medicineDose"
              width="100"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.medicineDose }}{{ scope.row.medicineDoseUnit }}</div>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              label="单价"
              align="center"
              width="100"
              sortable="custom"
              prop="price"
            ></el-table-column>
            <el-table-column
              header-align="center"
              label="生产厂家"
              width="150"
              show-overflow-tooltip
              align="center"
              prop="manufacturer"
            ></el-table-column>
            <el-table-column
              header-align="center"
              label="批准文号"
              align="center"
              width="180"
              prop="medicineCode"
            ></el-table-column>
          </el-table>
          <el-pagination
            v-show="isInfo"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="medicineCurrent"
            :page-sizes="[15, 30]"
            :page-size="medicineSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="medicineTotal"
          ></el-pagination>
        </div>
      </div>
    </div>

    <ListContentDialog ref="listContentDialog" @pickedObjectEmit="changeData"></ListContentDialog>
  </div>
</template>
<script>
import deepClone from '@/plugins/clone'
import ListContentDialog from './components/ListContentDialog.vue'

export default {
  name: 'DisposeScheme',
  components: {
    ListContentDialog
  },
  data() {
    return {
      dispose: {},
      disposeInfo: {},
      value: [],
      operationOptions: [],
      operationList: [],
      drugTable: [],
      medicationData: [],
      medicationTreeData: [],
      defaultProps: {
        children: 'items',
        label: 'label'
      },
      loading: false,
      medicationIsShow: false,
      operationIsShow: true,
      // 用药记录
      allMedicineData: [],
      selectRowList: [],
      doctorOptions: [],
      searchValue: '',
      medicineCurrent: 1,
      medicineSize: 30,
      medicineTotal: 0,
      isEdit: true,
      isInfo: false,
      tab: {
        index: 0
      },
      lastMedication: '',
      operationNameShow: '',
      checkedList: ['介入治疗'],
      disposeTypeList: ['介入治疗', '药物治疗']
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    solutionId: {
      type: String,
      default: () => ''
    },
    disposeId: {
      type: String,
      default: () => ''
    },
    infoType: {
      type: String,
      default: () => ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {},
  watch: {
    infoType: {
      handler(val) {
        if (val) {
          this.loading = true
          this.getOperationList()
          this.getAllMedicineData()
          this.getMedicationData()
          this.getDoctorList()
          if (this.infoType === 'add') {
            this.dispose = {}
            this.disposeInfo = {}
            this.operationNameShow = ''
            this.lastMedication = ''
            this.value = []
            this.drugTable = []
            this.checkedList = ['介入治疗']
            this.getLastMedication()
          }
          if (this.infoType === 'edit') {
            this.checkedList = []
            this.getDisposeInfo()
            this.getOperation()
            this.getDrugTable()
          }
          setTimeout(() => {
            this.editClick()
          }, 500)
        }
      },
      immediate: true
    },
    info: {
      handler(val) {
        if (val) {
          if (val.typeCategory !== '系统治疗') {
            this.disposeTypeList = ['介入治疗']
          } else {
            this.disposeTypeList = ['介入治疗', '药物治疗']
          }
        }
      },
      immediate: true
    },
    searchValue: {
      handler() {
        this.debounce(this.getAllMedicineData, 500)
      }
    }
  },
  created() {},
  methods: {
    // 手术列表
    getDoctorList() {
      this.$api.get('/v1/webconsole/study/doctors').then((res) => {
        this.doctorOptions = res.data.data
      })
    },
    getLastMedication() {
      if (this.list.length > 0) {
        this.$api
          .get(`/v1/webconsole/solution/dispose/drug/lastDispose/${this.solutionId}`)
          .then((res) => {
            this.lastDisposeDrugTable = res.data.data
          })
      }
    },
    selectRadio(val) {
      if (this.lastMedication === val) {
        this.lastMedication = ''
        this.drugTable = []
      } else {
        this.lastMedication = val
        this.drugTable = deepClone(this.lastDisposeDrugTable)
      }
    },
    handleChangeType(val) {
      if (val.length > 0) {
        if (val.length === 1) {
          if (val[0] === '药物治疗') {
            this.medicationIsShow = true
          } else {
            this.medicationIsShow = false
          }
          if (val[0] === '介入治疗') {
            this.operationIsShow = true
          } else {
            this.operationIsShow = false
          }
        } else if (val.length === 2) {
          this.medicationIsShow = true
          this.operationIsShow = true
        }
      } else {
        this.medicationIsShow = false
        this.operationIsShow = false
      }
    },
    Close() {
      this.isEdit = true
      this.medicationIsShow = false
      this.$emit('close')
    },
    // 获取处置信息
    getDisposeInfo() {
      this.$api.get(`/v1/webconsole/solution/dispose/show/${this.disposeId}`).then((res) => {
        this.dispose = res.data.data
      })
    },
    // 手术列表
    getOperationList() {
      this.$api.get('/v1/webconsole/study/puboperationlist').then((res) => {
        this.operationList = res.data.data
        this.operationOptions = this.operationList
      })
    },
    // 获取处置制定的手术
    getOperation() {
      this.$api
        .get(`/v1/webconsole/solution/dispose/operation/list/${this.disposeId}`)
        .then((res) => {
          this.dispose.puboperationIndex = []
          this.dispose.operationName = []
          this.operationNameShow = ''
          res.data.data.forEach((item) => {
            this.dispose.puboperationIndex.push(item.puboperationIndex)
            this.dispose.operationName.push(item.operationName)
          })

          this.value = this.dispose.puboperationIndex
          if (this.value.length > 0) {
            let isList = true
            this.checkedList.forEach((item) => {
              if (item === '介入治疗') {
                isList = false
              }
            })
            if (isList === true) {
              this.checkedList.push('介入治疗')
              this.operationIsShow = true
            }
          } else {
            // 查找要删除的元素的索引
            const index = this.checkedList.indexOf('介入治疗')

            // 如果找到了要删除的元素
            if (index !== -1) {
              // 使用 splice() 方法删除元素
              this.checkedList.splice(index, 1)
            }
            this.operationIsShow = false
          }
          this.operationNameShow = this.dispose.operationName.join('/')
        })
    },
    editClick() {
      this.isEdit = false
      this.loading = false
      this.disposeInfo = deepClone(this.dispose)
    },
    saveClick() {
      if (!this.disposeInfo.chiefComplaint) {
        return this.$message.warning('请填写主诉')
      }
      if (!this.disposeInfo.detail) {
        return this.$message.warning('请填写处置概述')
      }

      let data = {
        detail: this.disposeInfo.detail,
        chiefComplaint: this.disposeInfo.chiefComplaint,
        doctorId: this.disposeInfo.doctorId,
        disposeTime: this.disposeInfo.disposeTime,
        pubOperations: [],
        drugs: []
      }

      if (this.disposeId) {
        data.id = this.disposeId
      }

      if (this.operationIsShow === true) {
        data.pubOperations = this.value
      }

      if (this.medicationIsShow === true) {
        for (let i = 0; i < this.drugTable.length; i++) {
          data.drugs.push({
            pubdrugIndex: this.drugTable[i].pubdrugIndex,
            dose: this.drugTable[i].dose,
            doseUnit: this.drugTable[i].doseUnit
          })
        }
      }

      this.$api
        .post(`/v1/webconsole/solution/dispose/save/${this.solutionId}`, data)
        .then((res) => {
          if (res.data && res.data.data) {
            this.$message.success('保存成功')
            this.$emit('save')
          } else {
            this.$message.warning(res.data.msg)
          }
        })
        .finally(() => {
          this.isEdit = true
        })
    },

    dialogSave(val) {
      this.drugTable.push(val)
    },

    // 获取处置相关药品
    getDrugTable() {
      this.$api.get(`/v1/webconsole/solution/dispose/drug/list/${this.disposeId}`).then((res) => {
        this.drugTable = res.data.data
        if (this.drugTable.length > 0) {
          let isList = true
          this.checkedList.forEach((item) => {
            if (item === '药物治疗') {
              isList = false
            }
          })
          if (isList === true && this.info.typeCategory === '系统治疗') {
            this.checkedList.push('药物治疗')
            this.medicationIsShow = true
          }
        } else {
          // 查找要删除的元素的索引
          const index = this.checkedList.indexOf('药物治疗')

          // 如果找到了要删除的元素
          if (index !== -1) {
            // 使用 splice() 方法删除元素
            this.checkedList.splice(index, 1)
          }
          this.medicationIsShow = false
        }
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        setTimeout(() => {
          this.operationOptions = this.operationList.filter((item) => {
            return item.dictName.indexOf(query) > -1
          })
        }, 200)
      } else {
        this.operationOptions = this.operationList
      }
    },
    clear() {
      this.operationOptions = this.operationList
    },
    handleClick(row) {
      this.drugTable = this.drugTable.filter((e) => e.pubdrugIndex !== row.pubdrugIndex)
    },

    debounce(fn, wait) {
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
      this.medicineCurrent = 1
      this.timer = setTimeout(fn, wait)
    },
    handleMod2() {
      if (this.selectRowList.length > 0) {
        this.$refs.listContentDialog.Show(this.selectRowList, 'add')
      } else {
        return this.$message.warning('请选择要添加的药品')
      }
    },
    getAllMedicineData() {
      let formData = new FormData()
      formData.append('keyword', this.searchValue)
      formData.append('current', this.medicineCurrent)
      formData.append('size', this.medicineSize)
      this.$api.post(`/v1/webconsole/medication/medicines/list`, formData).then((res) => {
        this.medicineTotal = res.data.data.total
        this.allMedicineData = res.data.data.records
      })
    },
    getRowKeys(row) {
      return row.uuid
    },
    sortChange3({ prop, order }) {
      this.allMedicineData.sort(this.compare(prop, order))
    },
    compare(propertyName, sort) {
      function isNumberV(val) {
        var regPos = /^\d+(\.\d+)?$/ //非负浮点数
        var regNeg =
          /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ //负浮点数
        if (regPos.test(val) || regNeg.test(val)) {
          return true
        } else {
          return false
        }
      }

      return function (obj1, obj2) {
        let value1 = obj1[propertyName]
        let value2 = obj2[propertyName]
        // 数字类型的比较
        if (isNumberV(value1) || isNumberV(value2)) {
          if (sort === 'ascending') {
            return value1 - value2
          } else {
            return value2 - value1
          }
        }
        // 字符比较使用localeCompare()
        else {
          if (value1 && value2) {
            const res = value1.localeCompare(value2, 'zh')
            return sort === 'ascending' ? res : -res
          }
        }
      }
    },
    changeCurrentRow2(row) {
      if (row) {
        this.$refs.multipleTable2.toggleRowSelection(row)
      } else {
        this.$refs.multipleTable2.clearSelection()
      }
    },
    handleSelectionChange2(val) {
      this.selectRowList = val
    },
    handleSizeChange1(newSize) {
      this.medicineSize = newSize
      this.getAllMedicineData()
    },
    handleCurrentChange1() {
      this.medicineCurrent = newPage
      this.getAllMedicineData()
    },
    changeData(val) {
      val.forEach((item) => {
        this.drugTable.push(item)
      })
      this.selectRowList = null
      this.$refs.multipleTable2.clearSelection()
    },
    getMedicationData() {
      this.$api.get(`/v1/webconsole/medication/group/SOLUTION`).then((res) => {
        this.medicationData = res.data.data

        this.medicationTabClick(this.tab)
      })
    },
    medicationTabClick(tab) {
      this.$api
        .get(`/v1/webconsole/medication/packages/${this.medicationData[tab.index].uuid}`)
        .then((res) => {
          this.medicationTreeData = res.data.data
        })
    },
    handleCheckChangeMedication(val) {
      this.$refs.listContentDialog.Show(val, 'add1')
    }
  }
}
</script>
