<style scoped lang="less">
.top-item-row {
  margin: 0px 15px;
  display: flex;
  justify-content: space-between;
}

.item-row {
  margin: 0.5rem 0.5rem 1rem 1rem;
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.item-row-textarea {
  width: 90%;
  display: flex;
  margin: 1rem 0.5rem 0rem 0.5rem;
  justify-content: center;
  flex-direction: column;
}

.item-row > span {
  margin: 0 15px;
}

.input_has_unit {
  width: 200px;
  margin: 5px 0;
}
.input_item {
  margin: 5px 0;
  width: 200px;
}

.input-textarea {
  width: 100%;
}

.dialogContent::-webkit-scrollbar {
  width: 12px;
}

.dialogContent::-webkit-scrollbar-track {
  border-radius: 10px;
}

.dialogContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.dialogContent::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.dialogContent {
  flex: 1;
  overflow: auto;
}
</style>

<template>
  <CmDialogBorder size="small" :zIndex="zIndex" title="停用原因" v-show="isShow" @close="Close">
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialogContent">
      <div class="top-item-row">
        <div class="item-row-textarea">
          <div class="input-form-item-label">停用日期</div>
          <date-panel
            class="input_item"
            ref="datePanel"
            v-model="form.stopDate"
            type="date"
            align="center"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          ></date-panel>
        </div>
      </div>
      <div class="top-item-row">
        <div class="item-row-textarea">
          <div class="input-form-item-label">停用原因</div>
          <cqt-select
            class="input_item"
            activeUrl="/v1/webconsole/comboData/get/SOLUTION_stop_drug_reason"
            :value.sync="form.stopReason"
          />
        </div>
      </div>
      <div v-if="form.stopReason == '其他'" class="top-item-row">
        <div class="item-row-textarea">
          <div class="input-form-item-label">其他原因</div>
          <cqt-input
            class="input-textarea"
            style="margin: 5px 0"
            placeholder="请输入"
            type="textarea"
            v-model="form.stopReasonOther"
            size="large"
          />
        </div>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../../../components/CmDialogBorder.vue'
// cqt
import CqtInput from '../../../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../../../components/cqt/CqtRadio.vue'

import DatePanel from '../../../../../Menu/components/date-picker/index'

export default {
  name: 'AddFollowDialog',
  components: {
    CmDialogBorder,
    CqtInput,
    CqtSelect,
    CqtRadio,
    DatePanel
  },

  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      zIndex: 9,
      isShow: false,
      form: {
        stopDate: '',
        stopReason: '',
        stopReasonOther: ''
      }
    }
  },

  mounted() {},
  methods: {
    Show() {
      this.form = {}
      this.isShow = true
    },
    Close() {
      this.isShow = false
    },

    async Save() {
      if (!this.form.stopDate) return this.$message.warning('请选择停用日期！')
      if (!this.form.stopReason) return this.$message.warning('请输入停用原因！')
      this.$emit('save', this.form)
      this.Close()
    }
  }
}
</script>
